import './App.css';
import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import RegisterScreen from './components/RegisterScreen';
import LoginScreen from './components/LoginScreen';
import ForgotPasswordScreen from './components/ForgotPasswordScreen';
import OtpScreen from './components/OtpScreen';
import ProfileScreen from './components/ProfileScreen';
import FavoriteEventsScreen from './components/FavoriteEventsScreen';
import TicketsScreen from './components/TicketsScreen';
import HomeScreen from './components/HomeScreen';
import UpcomingEventsListScreen from './components/UpcomingEventsListScreen';
import OfferListScreen from './components/OfferListScreen';
import EventDetailsScreen from './components/EventDetailsScreen';
import TicketSelectionScreen from './components/TicketSelectionScreen';
import TicketConfirmScreen from './components/TicketConfirmScreen';
import ContactScreen from './components/ContactScreen';
import CommonScreen from './components/CommonScreen';
import PaymentScreen from './components/PaymentScreen';
import ThankyouScreen from './components/ThankyouScreen';
import ChangePasswordScreen from './components/ChangePasswordScreen';
import PdfGenerateScreen from './components/PdfGenerateScreen';

const Stack = createNativeStackNavigator();

// const linking = {
//   prefixes: ['https://newhiindia.alakmalak.ca/public/test','newhiindia.alakmalak.ca://'],
//   config: {
//     screens: {
//       // LoginScreen: {
//       //   path:'login-screen/:id',
//       //   parse: {
//       //     id: (id) => `${id}`,
//       //   },
//       //   stringify: {
//       //     id: (id) => id,
//       //   },
//       // },
//       // HomeScreen: 'home-screen'
//       // EventDetailsScreen: 'event-details/:id',
//       EventDetailsScreen: {
//         path:'event-details/:paramsEventId',
//         parse: {
//           paramsEventId: (id) => `${id}`,
//         },
//         stringify: {
//           paramsEventId: (id) => id,
//         },
//       },
//       ChangePasswordScreen: {
//         path:'change-password/:paramEmail',
//         parse: {
//           paramEmail: (id) => `user-${id}`,
//         },
//         stringify: {
//           paramEmail: (id) => id,
//         },
//       },
//     }
//   },
// };

const linking = {
  prefixes: ['https://hiindia.personality.alakmalak.ca/', 'hiindia.personality.alakmalak.ca://'],
  config: {
    screens: {
      HomeScreen: 'home',
      UpcomingEventsListScreen: 'upcoming-events-list',
      EventDetailsScreen: {
        path: 'event-details/:paramsEventId',
        parse: {
          paramsEventId: (id) => `${id}`,
        },
        stringify: {
          paramsEventId: (id) => id,
        },
      },
      ChangePasswordScreen: {
        path: 'change-password/:paramToken',
        parse: {
          paramToken: (id) => `${id}`,
        },
        stringify: {
          paramToken: (id) => id,
        },
      },
      ProfileScreen: {
        path: 'profile/:paramActiveTab',
        parse: {
          paramActiveTab: (id) => `${id}`,
        },
        stringify: {
          paramActiveTab: (id) => id,
        },
      },

    },
  },
};

function App() {
  return (
    <div className="App">

      <NavigationContainer
        linking={linking}
        // fallback={<HomeScreen />}
        documentTitle={{
          formatter: (options, route) =>
            `Hi India Events`,
        }}
      >
        <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName='HomeScreen'>
          <Stack.Screen name="LoginScreen" component={LoginScreen} />
          <Stack.Screen name="RegisterScreen" component={RegisterScreen} />
          <Stack.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} />
          <Stack.Screen name="OtpScreen" component={OtpScreen} />
          <Stack.Screen name="ProfileScreen" component={ProfileScreen} />
          <Stack.Screen name="FavoriteEventsScreen" component={FavoriteEventsScreen} />
          <Stack.Screen name="TicketsScreen" component={TicketsScreen} />
          <Stack.Screen name="HomeScreen" component={HomeScreen} />
          <Stack.Screen name="UpcomingEventsListScreen" component={UpcomingEventsListScreen} />
          <Stack.Screen name="OfferListScreen" component={OfferListScreen} />
          <Stack.Screen name="EventDetailsScreen" component={EventDetailsScreen} />
          <Stack.Screen name="TicketSelectionScreen" component={TicketSelectionScreen} />
          <Stack.Screen name="TicketConfirmScreen" component={TicketConfirmScreen} />
          <Stack.Screen name="ContactScreen" component={ContactScreen} />
          <Stack.Screen name="CommonScreen" component={CommonScreen} />
          <Stack.Screen name="PaymentScreen" component={PaymentScreen} />
          <Stack.Screen name="ThankyouScreen" component={ThankyouScreen} />
          <Stack.Screen name="ChangePasswordScreen" component={ChangePasswordScreen} />
          <Stack.Screen name="PdfGenerateScreen" component={PdfGenerateScreen} />

        </Stack.Navigator>
      </NavigationContainer>
    </div>
  );
}

export default App;
