import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { colors } from '../common/color';
import { FORGOTPASSWORD } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { USER_ID, getSession } from '../common/LocalStorage';
import { ids, styles } from '../common/responsive';

function ForgotPasswordScreen({ navigation }) {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    // forgot password
    const forgotPasswordApi = () => {

        if (email.trim().length == 0) {
            alert("Enter email");
        }
        else if (!validateEmail(email.trim())) {
            alert("Invalid email");
        }
        else {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "email": email,
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(FORGOTPASSWORD + "\n" + raw);

                setLoading(true);
                fetch(FORGOTPASSWORD, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.status == 1) {
                            setLoading(false);
                            alert(json.message);
                            setEmail("");
                            // navigation.navigate('ChangePasswordScreen', { paramEmail: email });
                        } else {
                            setLoading(false);
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }

        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.loginMainView}>

                        <Text style={styles.viewTitle}>Forgot Password</Text>

                        {/* email */}
                        <Text style={styles.textInputTitle}>Email</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={email}
                                onChangeText={txt => { setEmail(txt) }}
                                placeholderTextColor={colors.placeHolderColor}
                                placeholder="Enter Email"
                            />
                            <Image source={require("../assets/email2.png")} style={styles.textInputIcon} />
                        </View>

                        {/* forgot password button view */}
                        <Pressable style={styles.commonButtonView} onPress={forgotPasswordApi}>
                            <Text style={styles.commonButtonText}>Forgot Password</Text>
                        </Pressable>
                        {/* end of forgot password button view */}

                        {/* login link view */}
                        <View style={styles.bottomContainer}>
                            <Text style={styles.colorBlackText}>Back to </Text>
                            <Pressable onPress={() => navigation.navigate("LoginScreen")}>
                                <Text style={styles.createAccountText}>sign in</Text>
                            </Pressable>
                        </View>
                        {/* end of login link view */}

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default ForgotPasswordScreen;
