import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, } from '../common/utils';
import { colors } from '../common/color';
import { VERIFYOTP } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { ACCESS_TOKEN, CUSTOMER_ID, EMAIL, NAME, PHONE, SOCIAL_LOGIN, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { ids, styles } from '../common/responsive';

function OtpScreen({ navigation, route }) {

    var onlyDigitReg = /^\d+$/;

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    // verify otp
    const verifyOtp = () => {

        if (otp.trim().length == 0) {
            alert("Enter OTP");
        }
        else if (otp.length != 4) {
            alert("Enter 4-digit OTP");
        }
        else if (!onlyDigitReg.test(otp)) {
            alert("OTP should be only digits");
        }
        else {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "user_id": route.params.paramUserId,
                    "otp": otp,
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(VERIFYOTP + "\n" + raw);

                setLoading(true);
                fetch(VERIFYOTP, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        // alert(JSON.stringify(json));
                        if (json.status == 1) {
                            alert(json.message);
                            setLoading(false);

                            //store deatils
                            if (route.params.paramFrom == "register") {
                                saveSession(USER_ID, route.params.paramData.data.id);
                                saveSession(NAME, route.params.paramData.data.name);
                                saveSession(PHONE, route.params.paramData.data.phone);
                                saveSession(SOCIAL_LOGIN, route.params.paramData.data.is_social);
                                saveSession(EMAIL, route.params.paramData.data.email);
                                saveSession(CUSTOMER_ID, route.params.paramData.data.customer_id);
                            } else {
                                // saveSession(USER_ID, route.params.paramData.data.id);
                                // saveSession(NAME, route.params.paramData.data.name);
                                // saveSession(PHONE, route.params.paramData.data.phone);
                                // saveSession(SOCIAL_LOGIN, route.params.paramData.data.is_social);
                                // saveSession(EMAIL, route.params.paramData.data.email);
                                // saveSession(CUSTOMER_ID, route.params.paramData.data.customer_id);

                                saveSession(USER_ID, route.params.paramData.user_id);
                                saveSession(NAME, route.params.paramData.name);
                                saveSession(PHONE, route.params.paramData.phone);
                                saveSession(SOCIAL_LOGIN, route.params.paramData.social_login);
                                saveSession(EMAIL, route.params.paramData.email);
                                saveSession(CUSTOMER_ID, route.params.paramData.customer_id);
                            }


                            navigation.navigate('HomeScreen');
                            navigation.reset({
                                index: 0,
                                routes: [{ name: 'HomeScreen' }],
                            });
                        } else {
                            setLoading(false);
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }
        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* otp view */}
                    <View style={styles.loginMainView}>

                        <Text style={styles.viewTitle}>Verify OTP</Text>

                        {/* otp number */}
                        <Text style={styles.textInputTitle}>OTP</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={otp}
                                onChangeText={txt => { setOtp(txt.replace(/[^0-9]/g, '')) }}
                                placeholderTextColor={colors.placeHolderColor}
                                maxLength={4}
                                keyboardType='numeric'
                                inputMode='numeric'
                                placeholder="Enter OTP"
                            />
                            <Image source={require("../assets/password.png")} style={styles.textInputIcon} />
                        </View>

                        {/* verify otp button view */}
                        <Pressable style={styles.commonButtonView} onPress={verifyOtp}>
                            <Text style={styles.commonButtonText}>Verify OTP</Text>
                        </Pressable>
                        {/* end of verify otp button view */}

                    </View>
                    {/* end of otp view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default OtpScreen;
