import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { colors } from '../common/color';
import { useGoogleLogin } from '@react-oauth/google';
import { LOGIN, REGISTER, SENDOTP } from '../common/webUtils';
import { ACCESS_TOKEN, CUSTOMER_ID, EMAIL, NAME, PHONE, SOCIAL_LOGIN, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import { ids, styles } from '../common/responsive';

function RegisterScreen({ navigation }) {

    var onlyDigitReg = /^\d+$/;

    const [termsChecked, setTermsChecked] = React.useState(false);
    const [phoneNo, setPhoneNo] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    // sign in
    const signUpViaSimple = () => {

        if (fullName.trim().length == 0) {
            alert("Enter full name");
        }
        else if (email.trim().length == 0) {
            alert("Enter email");
        }
        else if (!validateEmail(email.trim())) {
            alert("Invalid email");
        }
        else if (password.trim().length == 0) {
            alert("Enter password");
        }
        else if (password.trim().length < 6) {
            alert("Please enter password more than 6 characters");
        }
        else if (phoneNo.trim().length == 0) {
            alert("Enter Mobile Number");
        }
        else if (phoneNo.length != 10) {
            alert("Enter 10-digit Mobile Number");
        }
        else if (!onlyDigitReg.test(phoneNo)) {
            alert("Mobile Number should be only digits");
        }
        else if (!termsChecked) {
            alert("Please select terms & conditions")
        }
        else {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "name": fullName,
                    "phone": phoneNo,
                    "email": email,
                    "password": password,
                    "social_login": "0"
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(REGISTER + "\n" + raw);

                setLoading(true);
                fetch(REGISTER, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.status == 1) {

                            // setLoading(false);

                            var myHeaders1 = new Headers();
                            myHeaders1.append("Content-Type", "application/json");

                            var raw1 = JSON.stringify({
                                "user_id": json.data.id
                            });

                            var requestOptions1 = {
                                method: 'POST',
                                headers: myHeaders1,
                                body: raw1,
                                redirect: 'follow'
                            };

                            CustomConsole(SENDOTP + "\n" + raw1);

                            fetch(SENDOTP, requestOptions1)
                                .then(response1 => response1.json())
                                .then((json1) => {

                                    setLoading(false);
                                    if (json1.status == 1) {
                                        navigation.navigate('OtpScreen', {
                                            paramUserId: json.data.id,
                                            paramData: json,
                                            paramFrom:"register"
                                        });
                                    } else {
                                        alert(json.message);
                                    }
                                })
                                .catch((error1) => {
                                    setLoading(false);
                                    CustomConsole("Send otp Error=>" + error1);
                                });

                            // navigation.reset({
                            //     index: 0,
                            //     routes: [{ name: 'HomeScreen' }],
                            // });
                        } else {
                            setLoading(false);
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }

        }
    }

    const login = useGoogleLogin({
        // console.log("SUccess=====>"+JSON.stringify(codeResponse))
        onSuccess: codeResponse => getUserSocialData(codeResponse.access_token),
        flow: 'implicit',
    });

    // social login api
    const getUserSocialData = (access_token) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch("https://www.googleapis.com/oauth2/v1/userinfo?access_token=" + access_token, requestOptions)
                .then(response => response.json())
                .then((json) => {

                    CustomConsole(json);
                    // alert(JSON.stringify(json));


                    var myHeaders1 = new Headers();
                    myHeaders1.append("Content-Type", "application/json");

                    var raw1 = JSON.stringify({
                        "email": json.email,
                        "password": "",
                        "social_login": "1",
                        "access_token": access_token,
                        "name": json.name
                    });

                    var requestOptions1 = {
                        method: 'POST',
                        headers: myHeaders1,
                        body: raw1,
                        redirect: 'follow'
                    };

                    CustomConsole(LOGIN + "\n" + raw1);

                    setLoading(true);
                    fetch(LOGIN, requestOptions1)
                        .then(response1 => response1.json())
                        .then((json1) => {

                            CustomConsole(json1);
                            if (json1.status == 1) {
                                //store deatils

                                saveSession(USER_ID, json1.user_id);
                                saveSession(NAME, json1.name);
                                saveSession(PHONE, json1.phone);
                                saveSession(SOCIAL_LOGIN, json1.social_login);
                                saveSession(EMAIL, json1.email);
                                saveSession(ACCESS_TOKEN, json1.access_token);
                                saveSession(CUSTOMER_ID, json.customer_id);

                                setLoading(false);
                                navigation.navigate('HomeScreen');
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'HomeScreen' }],
                                });
                            } else {
                                setLoading(false);
                                alert(json1.message);

                            }

                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Exception=>" + e + "");
        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>

                        <Text style={styles.viewTitle}>Register</Text>

                        {/* full name */}
                        <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Full Name</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={fullName}
                                onChangeText={txt => { setFullName(txt) }}
                                placeholderTextColor={colors.placeHolderColor}
                                placeholder="Enter Full Name"
                                dataSet={{ media: ids.textInput }}
                            />
                            <Image source={require("../assets/user.png")} style={styles.textInputIcon} />
                        </View>

                        {/* email */}
                        <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Email</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={email}
                                onChangeText={txt => { setEmail(txt) }}
                                placeholderTextColor={colors.placeHolderColor}
                                placeholder="Enter Email"
                                dataSet={{ media: ids.textInput }}
                            />
                            <Image source={require("../assets/email2.png")} style={styles.textInputIcon} />
                        </View>

                        {/* password */}
                        <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Password</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={password}
                                onChangeText={txt => { setPassword(txt) }}
                                placeholderTextColor={colors.placeHolderColor}
                                placeholder="Enter Password"
                                secureTextEntry={true}
                                dataSet={{ media: ids.textInput }}
                            />
                            <Image source={require("../assets/password.png")} style={styles.textInputIcon} />
                        </View>

                        {/* mobile number */}
                        <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Mobile Number</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={phoneNo}
                                onChangeText={txt => { setPhoneNo(txt.replace(/[^0-9]/g, '')) }}
                                placeholderTextColor={colors.placeHolderColor}
                                maxLength={10}
                                keyboardType='numeric'
                                inputMode='numeric'
                                placeholder="Enter Mobile Number"
                                dataSet={{ media: ids.textInput }}
                            />
                            <Image source={require("../assets/mobile2.png")} style={styles.textInputIcon} />
                        </View>

                        {/* terms & conditions */}
                        <View style={styles.termConditionsView}>
                            <input type="checkbox"
                                checked={termsChecked}
                                onChange={() => {
                                    setTermsChecked(!termsChecked);
                                }}
                                onValueChange={setTermsChecked}
                            />
                            <Pressable onPress={() => setTermsChecked(!termsChecked)} style={styles.termConditionsPressable}>
                                <Text style={styles.termConditionsText}>I Agree to <Text style={{}} onPress={() => navigation.navigate("CommonScreen", { paramScreen: "terms" })}>Terms and Conditions</Text></Text>
                                {/* <Pressable onPress={() => setTermsChecked(!termsChecked)}>
                                <Text style={styles.termConditionsText} dataSet={{media:ids.termConditionsText}}>I Agree to Terms and Conditions</Text> */}
                            </Pressable>
                        </View>

                        {/* sign up button view */}
                        <Pressable style={styles.commonButtonView} onPress={signUpViaSimple}>
                            <Text style={styles.commonButtonText} dataSet={{ media: ids.commonButtonText }}>Create Account</Text>
                        </Pressable>
                        {/* end of sign up button view */}

                        {/* <View style={{ alignSelf: "center", marginTop: 15 }}>
                            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                                <GoogleLogin
                                    onSuccess={credentialResponse => {
                                        console.log(credentialResponse);
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                />
                            </GoogleOAuthProvider>
                        </View> */}
                        {/* login with google */}
                        <Pressable style={styles.googleButtonView}
                            onPress={() => {
                                login();
                            }}>
                            <Image source={require("../assets/google_plus.png")} style={styles.googleIcon} />
                            <Text style={styles.commonButtonText}>Continue With Google</Text>
                        </Pressable>
                        {/* end of login with google */}

                        {/* login link view */}
                        <View style={styles.bottomContainer}>
                            <Text style={styles.colorBlackText} dataSet={{ media: ids.colorBlackText }}>Already have an account? </Text>
                            <Pressable onPress={() => navigation.navigate("LoginScreen")}>
                                {/* <Pressable onPress={() => navigation.navigate('OtpScreen')}> */}
                                <Text style={styles.createAccountText}>sign in</Text>
                            </Pressable>
                        </View>
                        {/* end of login link view */}

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default RegisterScreen;
