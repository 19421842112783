import React, { useState } from 'react';
import { StatusBar, View, Text, ScrollView, Pressable, Image, FlatList } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomDigits, FooterComponent, HeaderComponent, scrollToTop, } from '../common/utils';
import { useIsFocused } from '@react-navigation/native';
import {CUSTOMER_ID, USER_ID, getSession } from '../common/LocalStorage';
import { ids, styles } from '../common/responsive';
import { PAYMENTMETINTENT } from '../common/webUtils';

function TicketSelectionScreen({ navigation, route }) {

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [bookingTickets, setBookingTickets] = useState([]);

    const ticket_details = route.params.paramTicketDetails;
    const event_id = route.params.paramEventId;
    const event_title = route.params.paramEventName;
    const event_date = route.params.paramEventDate;
    const event_time = route.params.paramEventTime;
    const event_location = route.params.paramEventLocation;

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            CustomConsole(ticket_details);
        } catch (error) {

        }
    }

    // ticket item view
    const ticketItem = (item, index) => {
        //     "ticket_type": "kids",
        //   "ticket_price": 120,
        //   "quantity": 1,
        //   "ticket_id":1
        const isTicketInBooking = bookingTickets.find(element => element.ticket_type == item.title);
        // CustomConsole(isTicketInBooking)
        if(parseFloat(item.remaining_quantity)>0){
            return (
                <View style={styles.ticketTypeView} dataSet={{ media: ids.ticketTypeView }}>
                    <View>
                        <Text style={styles.ticketTypeText} dataSet={{ media: ids.ticketTypeText }}>{item.title}</Text>
                        <Text style={styles.ticketTypePrice} dataSet={{ media: ids.ticketTypePrice }}>{item.price}</Text>
                    </View>
    
                    {isTicketInBooking == undefined ?
                        <Pressable style={styles.ticketAddButton}
                            onPress={() => {
                                bookingTickets.push({
                                    "ticket_type": item.title,
                                    "ticket_price": item.price,
                                    "quantity": 1,
                                    "ticket_id": item.id
                                });
                                setBookingTickets([...bookingTickets]);
                                CustomConsole(bookingTickets)
                            }} >
                            <Text style={styles.ticketAddButtonText} dataSet={{ media: ids.ticketAddButtonText }}>Add</Text>
                        </Pressable>
                        :
                        <View style={styles.ticketQtyView}>
                            <Pressable style={styles.ticketPlusMinusView}
                                onPress={() => {
                                    const ticketBookingItem = bookingTickets.find(element => element.ticket_type == item.title);
                                    const ticketBookingItemIndex = bookingTickets.findIndex(element => element.ticket_type == item.title);
                                    CustomConsole("text=>  " + ticketBookingItem)
                                    if (ticketBookingItem.quantity > 1) {
                                        let tempArr = [...bookingTickets];
                                        let index = tempArr.findIndex(element => element.ticket_type == item.title);
                                        CustomConsole("Index=> ", index);
                                        tempArr[index].quantity = tempArr[index].quantity - 1;
                                        tempArr[index].ticket_price = CustomDigits(parseFloat(tempArr[index].ticket_price) - parseFloat(item.price));
                                        setBookingTickets(tempArr);
                                    } else {
                                        let tempArr = [...bookingTickets];
                                        tempArr.splice(ticketBookingItemIndex, 1);
                                        setBookingTickets(tempArr);
                                    }
                                }}>
                                <Text style={styles.ticketTypeText}>-</Text>
                            </Pressable>
                            <Text style={styles.ticketQtyText}>{isTicketInBooking.quantity}</Text>
                            <Pressable style={styles.ticketPlusMinusView}
                                onPress={() => {
                                    const ticketBookingItem = bookingTickets.find(element => element.ticket_type == item.title);
                                    CustomConsole("text=>  " + ticketBookingItem)
                                    if (ticketBookingItem.quantity < item.remaining_quantity) {
                                        let tempArr = [...bookingTickets];
                                        let index = tempArr.findIndex(element => element.ticket_type == item.title);
                                        CustomConsole("Index=> ", index);
                                        tempArr[index].quantity = tempArr[index].quantity + 1;
                                        tempArr[index].ticket_price = CustomDigits(parseFloat(tempArr[index].ticket_price) + parseFloat(item.price));
                                        setBookingTickets(tempArr)
                                    }
                                }}>
                                <Text style={styles.ticketTypeText}>+</Text>
                            </Pressable>
                        </View>}
                </View>
            );
        }
     
    }

    // total of ticket price
    const ticketTotal = () => {
        const total = bookingTickets.reduce((partialSum, a) => partialSum + (parseFloat(a.ticket_price)), 0);
        return total;
    }

    const payment_intents = async () => {
        const customer_id = await getSession(CUSTOMER_ID);
        const user_id = await getSession(USER_ID);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "amount": parseFloat(ticketTotal()) * 100,
            "customer_id": customer_id,
            "user_id": user_id,
            "currency": "usd"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        CustomConsole(PAYMENTMETINTENT + "\n" + raw);

        setLoading(true);
        fetch(PAYMENTMETINTENT, requestOptions)
            .then(response => response.json())
            .then(json => {
                // setClientSecret(json.data.clientSecret);
                setLoading(false);
                navigation.navigate("TicketConfirmScreen", {
                    paramEventId: event_id,
                    paramEventTicketType: bookingTickets,
                    paramEventTicketTotal: ticketTotal(),
                    paramEventName: event_title,
                    paramEventDate: event_date,
                    paramEventTime: event_time,
                    paramEventLocation: event_location,
                    clientSecret:json.data.client_secret
                });
            })
            .catch(error => {
                setLoading(false);
                CustomConsole("Error=>" + error);
            });

    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.ticketConfirmMainView}>
                        <Text style={styles.ticketSummeryText}>Ticket Summary</Text>

                        {/* ticket selection view */}
                        <View style={styles.ticketSelectionView}>

                            {/* ticket selection */}
                            <View style={styles.ticketSelectionTypesView} dataSet={{media:ids.ticketSelectionTypesView}}>
                                {ticket_details.map((item, index) => {
                                    return ticketItem(item, index);
                                })}
                                {/* <FlatList data={ticket_details}
                                    numColumns={2}
                                    extraData={ticket_details}
                                    renderItem={({ item, index }) => ticketItem(item, index)} /> */}

                            </View>
                            {/* end of ticket selection */}

                            {/* price summery view */}
                            <View style={styles.ticketSelectionpriceSummeryView}>
                                {/* <View style={styles.priceSummerySubView}>
                                    <Text style={styles.priceSummeryWhiteText}>Total fees</Text>
                                    <Text style={styles.priceSummeryTitleColorText}>{CustomDigits(ticketTotal())}</Text>
                                </View>
                                <View style={styles.priceSummerySubViewWithMarginTop}>
                                    <Text style={styles.priceSummeryWhiteText}>Service fees</Text>
                                    <Text style={styles.priceSummeryTitleColorText}>0</Text>
                                </View>
                                <Image source={require("../assets/footer_line_shape.png")} style={styles.priceSummeryDivider} /> */}

                                <View style={styles.priceSummerySubViewWithMarginTop}>
                                    <Text style={styles.priceSummeryWhiteText}>Total amount</Text>
                                    <Text style={styles.priceSummeryTitleColorText}>{CustomDigits(ticketTotal())}</Text>
                                </View>

                                <View style={styles.priceSummeryButtonView}>
                                    <Pressable onPress={() => navigation.goBack()}
                                        style={styles.priceSummeryBackButton}>
                                        <Text style={styles.priceSummeryButtonText}>Back</Text>
                                    </Pressable>
                                    <Pressable onPress={() => {
                                        if (bookingTickets.length == 0) {
                                            alert("Please select ticket");
                                        } else {
                                            payment_intents();
                                            // navigation.navigate("TicketConfirmScreen", {
                                            //     paramEventId: event_id,
                                            //     paramEventTicketType: bookingTickets,
                                            //     paramEventTicketTotal: ticketTotal(),
                                            //     paramEventName: event_title,
                                            //     paramEventDate: event_date,
                                            //     paramEventTime: event_time,
                                            //     paramEventLocation: event_location
                                            // });
                                        }
                                    }}
                                        style={styles.priceSummeryNextButton}>
                                        <Text style={styles.priceSummeryButtonText}>Next</Text>
                                    </Pressable>
                                </View>
                            </View>
                            {/* end of price summery view */}

                        </View>
                        {/* end of ticket selection view */}

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default TicketSelectionScreen;
