import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from './common/string';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51OUjV0SEHwpwfDJwiGqL3t8Kuq6Rkw6rYOIT9lozpCG2TjVcSgj7wxRzKtHMLPoogvKKWyW596rdCrvev5FYzaGp00EqJj7OHp');

// const options = {
//   // passing the client secret obtained from the server
//   clientSecret: 'sk_test_51OUjV0SEHwpwfDJwBnkcJoCoVtogeuOiwxElqAEFQ9O9X4Zq7DiDzBF8i0vWS5dcb0DyLMsI7IKoXXkhLbQHDce600cbafde0M',
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    {/* <Elements stripe={stripePromise}> */}
      <React.StrictMode>
        <App />
      </React.StrictMode>
    {/* </Elements> */}
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
