import React, { useState } from 'react';
import { StatusBar, View, Text, ScrollView, Pressable, Image, Dimensions, ImageBackground, FlatList } from 'react-native';
import { scrollToTop, } from '../common/utils';
import { useIsFocused } from '@react-navigation/native';
import { USER_ID, getSession } from '../common/LocalStorage';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

function PaymentScreen({ navigation, route }) {

    const [userId, setUserId] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // event.preventDefault();

        // if (!stripe || !elements) {
        //   // Stripe.js has not yet loaded.
        //   // Make sure to disable form submission until Stripe.js has loaded.
        //   return;
        // }

        // // Handle payment logic here
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { token, error } = await stripe.createToken(elements.getElement(CardElement));

        if (error) {
            console.error(error);
        } else {
            // Send the token to your server for server-side processing
            console.log(token);
            alert("Token==> " + token);
        }
    };

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <CardElement />
            <button type="submit" disabled={!stripe}>
                Pay
            </button>
        </form>
    );
};

export default PaymentScreen;
