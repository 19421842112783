import React, { useState } from 'react';
import { StatusBar, View, Text, ScrollView, Pressable, Image, FlatList } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, } from '../common/utils';
import { useIsFocused } from '@react-navigation/native';
import { CUSTOMER_ID, NAME, USER_ID, getSession } from '../common/LocalStorage';
import { EVENTBOOKING, PAYMENTMETINTENT } from '../common/webUtils';
import { ids, styles } from '../common/responsive.js';

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../components/CheckoutForm.js';
import { ImageBackground } from 'react-native';

function TicketConfirmScreen({ navigation, route }) {

    const [loading, setLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    // const [clientSecret, setClientSecret] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    const event_id = route.params.paramEventId;
    const event_ticket_type = route.params.paramEventTicketType;
    const event_ticket_total = route.params.paramEventTicketTotal;
    const event_title = route.params.paramEventName;
    const event_date = route.params.paramEventDate;
    const event_time = route.params.paramEventTime;
    const event_location = route.params.paramEventLocation;
    const clientSecret = route.params.clientSecret;
    // paramEventId: event_id,
    // paramEventTicketType: bookingTickets,
    // paramEventTicketTotal: ticketTotal()

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            const user_name = await getSession(NAME);
            setUserId(user_id);
            setUserName(user_name);
            // payment_intents();
        } catch (error) {

        }
    }

    // const payment_intents = async () => {
    //     const customer_id = await getSession(CUSTOMER_ID);
    //     const user_id = await getSession(USER_ID);

    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "amount": parseFloat(event_ticket_total) * 100,
    //         "customer_id": customer_id,
    //         "user_id": user_id,
    //         "currency": "usd"
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     CustomConsole(PAYMENTMETINTENT + "\n" + raw);

    //     fetch(PAYMENTMETINTENT, requestOptions)
    //         .then(response => response.json())
    //         .then(json => {
    //             CustomConsole(json);
    //             setClientSecret(json.data.clientSecret);
    //         })
    //         .catch(error => {
    //             setLoading(false);
    //             CustomConsole("Error=>" + error);
    //         });

    // }

    // generate unique string
    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    // stripe start
    const stripePromise = loadStripe('pk_test_BRBFA0vPVC1uQBquqiarNlWO00XD57JuLp');
    // India test : Just Use an Indian Stripe test card India (IN) 4000003560000008 Visa
    // API for payment intent : https://stripe.com/docs/api/payment_intents/create?shell=true&api=true&resource=payment_intents&action=create
    // Customer required to create in stripe and pass in above api
    const customAppearance = {
        theme: 'night',
        labels: 'floating'
    };

    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
        appearance: customAppearance
    };

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.ticketConfirmMainView} dataSet={{ media: ids.ticketConfirmMainView }}>

                        <Text style={styles.ticketSummeryText} dataSet={{ media: ids.ticketSummeryText }}>Ticket Summary</Text>

                        {/* ticket summery view */}
                        <View style={styles.ticketSummeryView} dataSet={{ media: ids.ticketSummeryView }}>

                            {/* ticket summery */}
                            <View style={{}}>
                                <Text style={styles.ticketEventTitle} dataSet={{ media: ids.ticketEventTitle }} numberOfLines={1}>{event_title}</Text>

                                <View style={styles.ticketUserDetails} dataSet={{ media: ids.ticketUserDetails }}>
                                    <View style={styles.ticketFlexOneView} dataSet={{ media: ids.ticketFlexOneView }}>
                                        <ImageBackground source={require("../assets/circlebg2.png")} style={styles.ticketSummeryIconBackround}>
                                            <Image source={require("../assets/user_ticket.png")} style={styles.ticketSummeryIcons} />
                                        </ImageBackground>
                                        <View style={styles.ticketSummeryIconsTextView}>
                                            <Text style={styles.ticketTitleText}>Visitor name</Text>
                                            <Text style={styles.ticketSubTitleText}>{userName}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.ticketFlexTwoView} dataSet={{ media: ids.ticketFlexTwoView }}>
                                        <ImageBackground source={require("../assets/circlebg2.png")} style={styles.ticketSummeryIconBackround}>
                                            <Image source={require("../assets/location.png")} style={styles.ticketSummeryIcons} />
                                        </ImageBackground>
                                        <View style={styles.ticketSummeryIconsTextView}>
                                            <Text style={styles.ticketTitleText}>Location</Text>
                                            <Text style={styles.ticketSubTitleText}>{event_location}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.ticketFlexOneView} dataSet={{ media: ids.ticketFlexOneView }}>
                                        <ImageBackground source={require("../assets/circlebg2.png")} style={styles.ticketSummeryIconBackround}>
                                            <Image source={require("../assets/calendar.png")} style={styles.ticketSummeryIcons} />
                                        </ImageBackground>
                                        <View style={styles.ticketSummeryIconsTextView}>
                                            <Text style={styles.ticketTitleText}>Date & Time</Text>
                                            <Text style={styles.ticketSubTitleText}>{event_date}, {event_time}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.ticketSeperatorView}>
                                    <Image source={require("../assets/halfcircle.png")} style={styles.ticketDotImage1} />
                                    <View style={styles.ticketLines} />
                                    <Image source={require("../assets/halfcircle.png")} style={styles.ticketDotImage2} />
                                </View>

                                <View style={styles.ticketDetails} ataSet={{ media: ids.ticketDetails }}>
                                    <View style={styles.ticketFlexOneView} ataSet={{ media: ids.ticketFlexOneView }}>
                                        <Text style={styles.ticketTitleText} ataSet={{ media: ids.ticketTitleText }}>Ticket type</Text>
                                    </View>
                                    <View style={styles.ticketFlexSecondView} ataSet={{ media: ids.ticketFlexSecondView }}>
                                        <Text style={styles.ticketTitleText} ataSet={{ media: ids.ticketTitleText }}>Quantity</Text>
                                    </View>
                                </View>

                                <FlatList data={event_ticket_type}
                                    renderItem={({ item, index }) => {
                                        return (
                                            <View style={styles.ticketTypeListView} ataSet={{ media: ids.ticketTypeListView }}>
                                                <View style={styles.ticketFlexOneView} ataSet={{ media: ids.ticketFlexOneView }}>
                                                    <Text style={styles.ticketSubTitleText} ataSet={{ media: ids.ticketSubTitleText }}>{item.ticket_type}</Text>
                                                </View>
                                                <View style={styles.ticketFlexSecondView} ataSet={{ media: ids.ticketFlexSecondView }}>
                                                    <Text style={styles.ticketSubTitleText} ataSet={{ media: ids.ticketSubTitleText }}>{item.quantity}</Text>
                                                </View>
                                            </View>
                                        );
                                    }} />
                            </View>
                            {/* end of ticket summery */}
                        </View>
                        {/* end of ticket summery view */}

                        {/* price summery view */}
                        <View style={styles.priceSummeryView} dataSet={{ media: ids.priceSummeryView }}>
                            {/* <View style={styles.priceSummerySubView}>
                                <Text style={styles.priceSummeryWhiteText}>Total fees</Text>
                                <Text style={styles.priceSummeryTitleColorText}>{event_ticket_total}</Text>
                            </View>
                            <View style={styles.priceSummerySubViewWithMarginTop}>
                                <Text style={styles.priceSummeryWhiteText}>Service fees</Text>
                                <Text style={styles.priceSummeryTitleColorText}>0</Text>
                            </View>

                            <Image source={require("../assets/footer_line_shape.png")} style={styles.priceSummeryDivider} /> */}
                            <View style={styles.priceSummerySubViewWithMarginTop}>
                                <Text style={styles.priceSummeryWhiteText} dataSet={{ media: ids.priceSummeryWhiteText }}>Total amount</Text>
                                <Text style={styles.priceSummeryTitleColorText} dataSet={{ media: ids.priceSummeryTitleColorText }}>{event_ticket_total}</Text>
                            </View>
                        </View>
                        {/* end of price summery view */}

                        {showPayment ? <View style={styles.paymentConfirmView}>
                            <Text style={styles.ticketPaymentText} dataSet={{ media: ids.ticketPaymentText }}>Payment</Text>

                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm eventId={event_id} eventTicketType={event_ticket_type} eventTicketTotal={event_ticket_total} navigation={navigation} />
                            </Elements>
                        </View> : null}

                        {/* button view */}
                        {!showPayment ? <View style={styles.priceSummeryButtonView}>
                            <Pressable onPress={() => navigation.goBack()}
                                style={styles.priceSummeryBackButton} dataSet={{ media: ids.priceSummeryBackButton }}>
                                <Text style={styles.priceSummeryButtonText} dataSet={{ media: ids.priceSummeryButtonText }}>Back</Text>
                            </Pressable>
                            <Pressable onPress={async () => {
                                // navigation.navigate("PaymentScreen")
                                setShowPayment(!showPayment);
                            }}
                                style={styles.priceSummeryNextButton} dataSet={{ media: ids.priceSummeryNextButton }}>
                                <Text style={styles.priceSummeryButtonText} dataSet={{ media: ids.priceSummeryButtonText }}>Next</Text>
                            </Pressable>
                        </View> : null}
                        {/* end of button view */}

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default TicketConfirmScreen;
