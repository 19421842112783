import React, { useState, useEffect } from 'react';
import { StatusBar, View, Text, ScrollView, useWindowDimensions, ImageBackground, Pressable, } from 'react-native';
import { externalStyles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, getPlusJakartaSansBoldFont, getPlusJakartaSansRegularFont, scrollToTop, } from '../common/utils';
import { colors } from '../common/color';
import { OTHERSCREENS } from '../common/webUtils';
import { USER_ID, getSession, } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import RenderHtml from 'react-native-render-html';
import { styles,ids } from '../common/responsive';

function ThankyouScreen({ navigation }) {

    const { width } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [image, setImage] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    return (
        <View style={externalStyles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.thankYouMainView} dataSet={{media:ids.thankYouMainView}}>

                        <Text style={styles.thankYouText} dataSet={{media:ids.thankYouText}}>Thank You</Text>
                        <Text style={styles.thankYouSubText} dataSet={{media:ids.thankYouSubText}}>Your booking has been done.</Text>

                        <Pressable onPress={() => {
                            navigation.navigate("ProfileScreen",{paramActiveTab:"Tickets"})
                        }} style={styles.viewTicketButtonView}>
                            <Text style={styles.viewTicketButtonText} dataSet={{media:ids.viewTicketButtonText}}>View Your Tickets</Text>
                        </Pressable>

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default ThankyouScreen;

const tagsStyles = {
    p: {
        color: colors.white,
        fontFamily: getPlusJakartaSansRegularFont()
    },
    div: {
        width: '500px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // textOverflow: 'ellipsis'
    }
}