import React, { useState } from 'react';
import { StatusBar, View, Text, ScrollView, Pressable, Image, FlatList, ImageBackground, } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomBorder, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, getMonthName, getOnlyDateNo, scrollToTop, } from '../common/utils';
import { useIsFocused } from '@react-navigation/native';
import { COUNTRY_CODE, USER_ID, getSession } from '../common/LocalStorage';
import { ADDFAVORITEEVENT, CATEGORY, UPCOMINGEVENTS } from '../common/webUtils';
import { ids, styles } from '../common/responsive.js'

function UpcomingEventsListScreen({ navigation, route }) {

    const [userId, setUserId] = useState('');
    const [upcomingEventsList, setUpcomingEventsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageNo, setPageNo] = useState(1);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);

            if (route.params) {
                upcomingListApi(route.params.paramsCategoryId, user_id);
            } else {
                upcomingListApi("", user_id);
            }

        } catch (error) {

        }
    }

    // upcoming events list api
    const upcomingListApi = async (event_category_type_id, user_id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const country_code = await getSession(COUNTRY_CODE);

            var raw = JSON.stringify({
                "type": "",
                "event_category_type_id": event_category_type_id,
                "page_size": "10",
                "page_number": pageNo,
                "city_id": "",
                "country_code": "231",
                "user_id": user_id,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(UPCOMINGEVENTS + "\n" + raw);

            setLoading(true);
            fetch(UPCOMINGEVENTS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    upcomingEventsList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        upcomingEventsList.push({
                            index: i,
                            event_id: json.data[i].event_id,
                            event_title: json.data[i].event_title,
                            event_images: json.data[i].event_images,
                            date: json.data[i].date,
                            dateNo: getOnlyDateNo(new Date(json.data[i].date)),
                            month_name: getMonthName(new Date(json.data[i].date)),
                            time: json.data[i].time,
                            location: json.data[i].location,
                            price: json.data[i].ticket_price_range[0] + "-" + json.data[i].ticket_price_range[1],
                            qr_code: json.data[i].qr_code,
                            description: json.data[i].description,
                            term_n_condition: json.data[i].term_n_condition,
                            is_favorite: json.data[i].is_favorite,
                            faqs: json.data[i].faqs,

                        });
                    }

                    setUpcomingEventsList([...upcomingEventsList]);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Upcoming Events List Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Upcoming Events List Exception=>" + e + "");
        }
    }

    // add favorite events  api
    const addFavouriteEventApi = async (event_id, is_fav) => {
        try {

            if (userId == '' || userId == null) {
                navigation.navigate("RegisterScreen");
            } else {
                let user_id = await getSession(USER_ID);

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "user_id": user_id,
                    "event_id": event_id,
                    "is_favourite": is_fav
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(ADDFAVORITEEVENT + "\n" + raw);

                fetch(ADDFAVORITEEVENT, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        console.log(json);

                        if (json.status == 1) {
                            var tempCatArr = [...upcomingEventsList]; //copy upcoming events array
                            var tempEventArrIndex = tempCatArr.findIndex(element => element.event_id === event_id); //find index of image array of particular events
                            tempCatArr[tempEventArrIndex].is_favorite = is_fav; //assign is_fav value 0/1
                            setUpcomingEventsList(tempCatArr); //set temparr to upcoming events
                            alert(json.message);
                            // setLoading(false);
                        } else {
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        console.log("Error=>" + error);
                        // setLoading(false);
                        alert("Please try again!");
                    });
            }
        } catch (e) {
            console.log("Exception=>" + e + "");
            // setLoading(false);
            alert("Please try again!");
        }
    }

    // upcoming events item view
    const upcomingEventsItem = (item, index) => {
        return (
            <Pressable onPress={() => navigation.navigate("EventDetailsScreen", { paramsEventId: item.event_id })}
                style={styles.upcomingEventItemMainView} dataSet={{media:ids.upcomingEventItemMainView}}>
                <Image source={{ uri: item.event_images[0] }}
                    style={styles.upcomingEventItemImage}
                    dataSet={{media:ids.upcomingEventItemImage}}
                />
                <Pressable style={styles.upcomingEventItemLikeView} onPress={() => addFavouriteEventApi(item.event_id, (item.is_favorite == "" || item.is_favorite == "0") ? "1" : "0")}>
                    <Image source={require("../assets/like.png")} style={(item.is_favorite == "" || item.is_favorite == "0") ? styles.upcomingEventLikeInActive : styles.upcomingEventLikeActive} />
                </Pressable>
                <View style={styles.upcomingEventSubview}>
                    <ImageBackground source={require("../assets/datebg.png")} style={styles.upcomingEventDateBackground}>
                        <Text style={styles.upcomingEventDateText}>{item.dateNo}</Text>
                        <Text style={styles.upcomingEventDateText}>{item.month_name}</Text>
                    </ImageBackground>
                    <View style={styles.upcomingEventSecondSubview}>
                        <Text style={styles.upcomingEventTitle} numberOfLines={2} dataSet={{media:ids.upcomingEventTitle}}>{item.event_title}</Text>
                        <View style={styles.upcomingEventLocation}>
                            <Image source={require("../assets/location.png")} style={styles.upcomingEventLocationIcon} />
                            <Text style={styles.upcomingEventLocationText} numberOfLines={1}>{item.location}</Text>
                        </View>
                        <ImageBackground source={require("../assets/pricebg.png")} style={styles.upcomingEventPriceView} imageStyle={styles.upcomingEventPriceBgImage}>
                            <Image source={require("../assets/ticket.png")} style={styles.upcomingEventPriceIcon} />
                            <Text style={styles.upcomingEventPriceText} numberOfLines={1}>{item.price}/Person </Text>
                        </ImageBackground>
                    </View>
                </View>
            </Pressable>
        );
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.upcomingEventView}dataSet={{media:ids.upcomingEventView}}>

                        {/* banner view */}
                        <ImageBackground source={require("../assets/evenlist-banner.png")} style={styles.offerBannerBackground} imageStyle={styles.offerBannerBackgroundImage} resizeMode='stretch'>
                            <Text style={styles.offerBannerText} dataSet={{media:ids.offerBannerText}}>upcoming event</Text>
                            {CustomBorder()}
                            <Text style={styles.offerBannerDescription} dataSet={{media:ids.offerBannerDescription}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>
                        </ImageBackground>
                        {/* end of banner view */}

                        {/* upcoming events list */}
                        {/* <FlatList style={styles.offerList}
                            data={upcomingEventsList}
                            ItemSeparatorComponent={<View style={styles.offerSeparator} />}
                            renderItem={({ item, index }) => upcomingEventsItem(item, index)} /> */}
                        <View style={[styles.upcomingEventListView,{marginTop:50}]} dataSet={{media:ids.upcomingEventListView}}>
                            {upcomingEventsList.map((item, index) => {
                                return upcomingEventsItem(item, index);
                            })}
                        </View>
                        {/* end of upcoming events list */}

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default UpcomingEventsListScreen;
