import React, { useState } from 'react';
import { StatusBar, View, Text, ScrollView, Pressable, Image, FlatList, ImageBackground, Linking, StyleSheet, Platform } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomBorder, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, getMonthName, getOnlyDateNo, scrollToTop, } from '../common/utils';
import { useIsFocused } from '@react-navigation/native';
import { COUNTRY_CODE, USER_ID, getSession } from '../common/LocalStorage';
import { ADDFAVORITEEVENT, BANNER, CATEGORY, CITY, UPCOMINGEVENTS } from '../common/webUtils';
import { Picker } from '@react-native-picker/picker';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ids, styles } from '../common/responsive.js'
import { useWindowDimensions } from 'react-native';

let page_var = 1;

function HomeScreen({ navigation }) {

    const [userId, setUserId] = useState('');
    const [city, setCity] = useState('');
    const [cityId, setCityId] = useState('');
    const [loading, setLoading] = useState(false);
    const [eventType, setEventType] = useState('1');
    const [activeIndex, setActiveIndex] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [bannerList, setBannerList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [upcomingEventsList, setUpcomingEventsList] = useState([]);
    const [extraUpcomingEventsList, setExtraUpcomingEventsList] = useState([]);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        page_var = 1;
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            bannerListApi();
            cityListApi();
        } catch (error) {

        }
    }

    // banner image list api
    const bannerListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(BANNER);

            setLoading(true);
            fetch(BANNER, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    bannerList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        bannerList.push({
                            index: i,
                            id: json.data[i].id,
                            title: json.data[i].title,
                            subtitle: json.data[i].subtitle,
                            image: json.data[i].image,
                            status: json.data[i].status,
                            created_at: json.data[i].created_at,
                            updated_at: json.data[i].updated_at,
                            order: json.data[i].order,
                            button_url: json.data[i].button_url,
                            button_title: json.data[i].button_title,
                        });
                    }

                    setBannerList(bannerList);
                    categoryListApi();
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Banner Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Banner Exception=>" + e + "");
        }
    }

    // city list api
    const cityListApi = async () => {
        try {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const country_code = await getSession(COUNTRY_CODE);

            var raw = JSON.stringify({
                "country_id": "233"
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(CITY + "\n" + raw);

            setLoading(true);
            fetch(CITY, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    cityList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        cityList.push({
                            index: i,
                            city_id: json.data[i].city_id,
                            city_name: json.data[i].city_name,
                        });
                    }

                    setCityList(cityList);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("City List Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("City List Exception=>" + e + "");
        }
    }

    // category list api
    const categoryListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(CATEGORY);

            //setLoading(true);
            fetch(CATEGORY, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    categoryList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        categoryList.push({
                            index: i,
                            category_id: json.data[i].category_id,
                            category_name: json.data[i].category_name,
                            category_icon: json.data[i].category_icon,
                        });
                    }

                    setCategoryList([...categoryList]);
                    upcomingListApi(eventType, "", page_var);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Banner Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Banner Exception=>" + e + "");
        }
    }

    // upcoming events list api
    const upcomingListApi = async (eventType, cityId, page_var) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const country_code = await getSession(COUNTRY_CODE);

            var raw = JSON.stringify({
                "type": "",
                "event_category_type_id": "",
                "page_size": "50",
                "page_number": "1",
                "city_id": cityId,
                "country_code": "231",
                "user_id": userId,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(UPCOMINGEVENTS + "\n" + raw);

            CustomConsole("page no=> ", page_var);

            // setLoading(true);
            fetch(UPCOMINGEVENTS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    upcomingEventsList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        upcomingEventsList.push({
                            index: i,
                            event_id: json.data[i].event_id,
                            event_title: json.data[i].event_title,
                            event_images: json.data[i].event_images,
                            date: json.data[i].date,
                            dateNo: getOnlyDateNo(new Date(json.data[i].date)),
                            month_name: getMonthName(new Date(json.data[i].date)),
                            time: json.data[i].time,
                            location: json.data[i].location,
                            price: json.data[i].ticket_price_range[0] + "-" + json.data[i].ticket_price_range[1],
                            qr_code: json.data[i].qr_code,
                            description: json.data[i].description,
                            term_n_condition: json.data[i].term_n_condition,
                            is_favorite: json.data[i].is_favorite,
                            faqs: json.data[i].faqs,
                            type: json.data[i].type,

                        });
                    }
                    page_var = page_var + 1;
                    setUpcomingEventsList([...upcomingEventsList]);
                    setExtraUpcomingEventsList([...upcomingEventsList]);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Upcoming Events List Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Upcoming Events List Exception=>" + e + "");
        }
    }

    // add favorite events  api
    const addFavouriteEventApi = async (event_id, is_fav) => {
        try {
            if (userId == '' || userId == null) {
                navigation.navigate("RegisterScreen");
            } else {
                let user_id = await getSession(USER_ID);

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "user_id": user_id,
                    "event_id": event_id,
                    "is_favourite": is_fav
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(ADDFAVORITEEVENT + "\n" + raw);

                setLoading(true);
                fetch(ADDFAVORITEEVENT, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        console.log(json);

                        if (json.status == 1) {
                            var tempCatArr = [...upcomingEventsList]; //copy upcoming events array
                            var tempEventArrIndex = tempCatArr.findIndex(element => element.event_id === event_id); //find index of image array of particular events
                            tempCatArr[tempEventArrIndex].is_favorite = is_fav; //assign is_fav value 0/1
                            setUpcomingEventsList(tempCatArr); //set temparr to upcoming events
                            alert(json.message);
                            setLoading(false);
                        } else {
                            setLoading(false);
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        console.log("Error=>" + error);
                        setLoading(false);
                        alert("Please try again!");
                    });
            }

        } catch (e) {
            console.log("Exception=>" + e + "");
            setLoading(false);
            alert("Please try again!");
        }
    }

    // banner item view
    const bannerItem = (item, index) => {
        return (
            <Pressable style={{}} onPress={() => Linking.openURL(item.button_url)}>
                <Image source={{ uri: item.image }} style={styles.bannerImage} dataSet={{ media: ids.bannerImage }} />
            </Pressable>
        );
    }

    // banner swiper item view
    const bannerSwiperItem = (item, index) => {
        return (
            <div onClick={() => Linking.openURL(item.button_url)}>
                <img src={item.image} style={styles.bannerSwiperImage} dataSet={{ media: ids.bannerSwiperImage }} />
            </div>
        );
    }

    // category item view
    const categoryItem = (item, index) => {
        return (
            <Pressable key={index} onPress={() => navigation.navigate("UpcomingEventsListScreen", { paramsCategoryId: item.category_id })} dataSet={{ media: ids.categoryItem }}>
                <ImageBackground source={require("../assets/circlebg.png")} style={styles.categoryItemBackgroundImage} dataSet={{ media: ids.categoryItemBackgroundImage }}>
                    <Image source={{ uri: item.category_icon }} style={styles.categoryItemIcon} dataSet={{ media: ids.categoryItemIcon }} />
                    <Text style={styles.categoryItemText} numberOfLines={1} dataSet={{ media: ids.categoryItemText }}>{item.category_name}</Text>
                </ImageBackground>
            </Pressable>
        );
    }

    // upcoming events item view
    const upcomingEventsItem = (item, index) => {
        return (
            <Pressable key={index} onPress={() => navigation.navigate("EventDetailsScreen", { paramsEventId: item.event_id })}
                style={styles.upcomingEventItemMainView} dataSet={{ media: ids.upcomingEventItemMainView }}>
                <Image source={{ uri: item.event_images[0] }}
                    style={styles.upcomingEventItemImage} dataSet={{ media: ids.upcomingEventItemImage }} />
                <Pressable style={styles.upcomingEventItemLikeView} onPress={() => addFavouriteEventApi(item.event_id, (item.is_favorite == "" || item.is_favorite == "0") ? "1" : "0")}>
                    <Image source={require("../assets/like.png")} style={(item.is_favorite == "" || item.is_favorite == "0") ? styles.upcomingEventLikeInActive : styles.upcomingEventLikeActive} />
                </Pressable>
                <View style={styles.upcomingEventSubview}>
                    <ImageBackground source={require("../assets/datebg.png")} style={styles.upcomingEventDateBackground}>
                        <Text style={styles.upcomingEventDateText}>{item.dateNo}</Text>
                        <Text style={styles.upcomingEventDateText}>{item.month_name}</Text>
                    </ImageBackground>
                    <View style={styles.upcomingEventSecondSubview}>
                        <Text style={styles.upcomingEventTitle} numberOfLines={2}>{item.event_title}</Text>
                        <View style={styles.upcomingEventLocation}>
                            <Image source={require("../assets/location.png")} style={styles.upcomingEventLocationIcon} />
                            <Text style={styles.upcomingEventLocationText} numberOfLines={1}>{item.location}</Text>
                        </View>
                        <ImageBackground source={require("../assets/pricebg.png")} style={styles.upcomingEventPriceView} imageStyle={styles.upcomingEventPriceBgImage}>
                            <Image source={require("../assets/ticket.png")} style={styles.upcomingEventPriceIcon} />
                            <Text style={styles.upcomingEventPriceText} numberOfLines={1}>{item.price} /Person</Text>
                        </ImageBackground>
                    </View>
                </View>
            </Pressable>
        );
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>
                    {/* {loading} */}

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View>

                        {/* banner view */}
                        <View style={styles.bannerView} dataSet={{ media: ids.bannerView }}>
                            {/* <FlatList
                                style={styles.bannerListView}
                                data={bannerList}
                                keyExtractor={(item) => item.id}
                                showsHorizontalScrollIndicator={false}
                                horizontal
                                scrollEventThrottle={32}
                                pagingEnabled
                                onScroll={(event) => {
                                    const index = Math.floor(
                                        Math.floor(event.nativeEvent.contentOffset.x) /
                                        Math.floor(event.nativeEvent.layoutMeasurement.width)
                                    );
                                    // CustomConsole("Index==> " + index);
                                    setActiveIndex(index);
                                    // work with: index
                                }}
                                ItemSeparatorComponent={<View style={styles.bannerSeparator} />}
                                renderItem={({ item, index }) => bannerItem(item, index)} /> */}
                            <Carousel
                                emulateTouch={true}
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}>
                                {bannerList.map((item, index) => {
                                    return bannerSwiperItem(item, index);
                                })}
                            </Carousel>
                        </View>
                        {/* end of banner view */}

                        {/* <FlatList style={{ alignSelf: 'center' }}
                            data={bannerList}
                            horizontal
                            renderItem={({ item, index }) => {
                                return (
                                    <View style={activeIndex == index ? styles.active_tab_circle : styles.inactive_tab_circle} />
                                );
                            }}
                        /> */}

                        {/* popular category view */}
                        <View style={styles.categoryView} dataSet={{ media: ids.categoryView }}>
                            <Text style={styles.ourProductsText} dataSet={{ media: ids.ourProductsText }}>Our Products</Text>
                            {CustomBorder()}
                            <Text style={styles.ourProductsDescription} dataSet={{ media: ids.ourProductsDescription }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>

                            <View style={styles.categoryListView} dataSet={{ media: ids.categoryListView }}>
                                {categoryList.map((item, index) => {
                                    return categoryItem(item, index);
                                })}
                            </View>
                            {/* <FlatList style={{ maxWidth: "100%" }}
                                data={categoryList}
                                // numColumns={2}
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}
                                renderItem={({ item, index }) => categoryItem(item, index)} /> */}
                        </View>
                        {/* end of popular category view */}

                        {/* upcoming events */}
                        <View style={styles.upcomingEventView} dataSet={{ media: ids.upcomingEventView }}>
                            <Text style={styles.ourProductsText} dataSet={{ media: ids.ourProductsText }}>upcoming event</Text>
                            {CustomBorder()}
                            <Text style={styles.ourProductsDescription} dataSet={{ media: ids.ourProductsDescription }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>

                            {/* tab view */}
                            <View style={styles.upcomingEventTypeTabView}>
                                <Pressable onPress={() => {
                                    setEventType("1");
                                    setUpcomingEventsList([...extraUpcomingEventsList]);
                                    // upcomingListApi("1");
                                }}
                                    style={eventType == "1" ? styles.upcomingEventTypeActiveTab : styles.upcomingEventTypeInActiveTab}>
                                    <Text style={styles.upcomingEventTypeTabText}>All</Text>
                                </Pressable>
                                <Pressable onPress={() => {
                                    setEventType("2");
                                    setUpcomingEventsList(extraUpcomingEventsList.filter(element => element.type == 2));
                                    // upcomingListApi("2");
                                }}
                                    style={eventType == "2" ? styles.upcomingEventTypeActiveTab : styles.upcomingEventTypeInActiveTab}>
                                    <Text style={styles.upcomingEventTypeTabText}>FREE</Text>
                                </Pressable>
                                <Pressable onPress={() => {
                                    setEventType("3");
                                    // upcomingListApi("3");
                                    setUpcomingEventsList(extraUpcomingEventsList.filter(element => element.type == 3));
                                }}
                                    style={eventType == "3" ? styles.upcomingEventTypeActiveTab : styles.upcomingEventTypeInActiveTab}>
                                    <Text style={styles.upcomingEventTypeTabText}>PAID</Text>
                                </Pressable>

                                {/* <View style={{}}>
                                    <Picker
                                        style={styles.pickerStyle}
                                        selectedValue={cityId}
                                        onValueChange={(itemValue, itemIndex) => {
                                            setCityId(itemValue);
                                            if (itemValue != "") {
                                                let cityData = cityList.find(element => element.city_id == itemValue);
                                                setCity(cityData.city_name);
                                            } else {
                                                setCity("");
                                            }
                                            upcomingListApi(eventType, itemValue, 1);
                                        }}>
                                        <Picker.Item label="Please Select" value="" />
                                        {cityList.map((item) => (
                                            <Picker.Item label={item.city_name} value={item.city_id} key={item.city_id} />
                                        ))}
                                    </Picker>
                                </View> */}

                            </View>
                            {/* end of tab view */}

                            {/* upcoming events list */}
                            {/* <FlatList style={{}}
                                data={upcomingEventsList}
                                pagingEnabled={true}
                                ItemSeparatorComponent={<View style={styles.upcomingEventSeparator} />}
                                renderItem={({ item, index }) => upcomingEventsItem(item, index)} /> */}
                            <View style={styles.upcomingEventListView} dataSet={{ media: ids.upcomingEventListView }}>
                                {upcomingEventsList.map((item, index) => {
                                    return upcomingEventsItem(item, index);
                                })}
                            </View>
                            {/* end of upcoming events list */}

                        </View>
                        {/* end of upcoming events */}

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default HomeScreen;
